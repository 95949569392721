import type { FC } from 'react';

const Footer: FC = () => {
  return (
    <div className="py-10 text-center rounded-tl-lg rounded-tr-lg bg-default">
      <p className="text-xl text-white">GOMMAIRE</p>
    </div>
  );
};
export default Footer;
