export default `/* CONFIG STYLES Please do not delete and edit CSS styles below */
/* IMPORTANT THIS STYLES MUST BE ON FINAL EMAIL */
#outlook a {
	padding: 0;
}
.es-button {
	mso-style-priority: 100 !important;
	text-decoration: none !important;
}
a[x-apple-data-detectors] {
	color: inherit !important;
	text-decoration: none !important;
	font-size: inherit !important;
	font-family: inherit !important;
	font-weight: inherit !important;
	line-height: inherit !important;
}
.es-desk-hidden {
	display: none;
	float: left;
	overflow: hidden;
	width: 0;
	max-height: 0;
	line-height: 0;
	mso-hide: all;
}
/*
END OF IMPORTANT
*/
body {
	width: 100%;
	font-family: verdana, geneva, sans-serif;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}
table {
	mso-table-lspace: 0pt;
	mso-table-rspace: 0pt;
	border-collapse: collapse;
	border-spacing: 0px;
}
table td,
body,
.es-wrapper {
	padding: 0;
	margin: 0;
}
.es-content,
.es-header,
.es-footer {
	table-layout: fixed !important;
	width: 100%;
}
img {
	display: block;
	border: 0;
	outline: none;
	text-decoration: none;
	-ms-interpolation-mode: bicubic;
}
p,
hr {
	margin: 0;
}
h1, h2, h3, h4, h5, h6 {
	margin: 0;
	line-height: 120%;
	mso-line-height-rule: exactly;
	font-family: arial, 'helvetica neue', helvetica, sans-serif;
}
p,
ul li,
ol li,
a {
	-webkit-text-size-adjust: none;
	-ms-text-size-adjust: none;
	mso-line-height-rule: exactly;
}
.es-left {
	float: left;
}
.es-right {
	float: right;
}
.es-p5 {
	padding: 5px;
}
.es-p5t {
	padding-top: 5px;
}
.es-p5b {
	padding-bottom: 5px;
}
.es-p5l {
	padding-left: 5px;
}
.es-p5r {
	padding-right: 5px;
}
.es-p10 {
	padding: 10px;
}
.es-p10t {
	padding-top: 10px;
}
.es-p10b {
	padding-bottom: 10px;
}
.es-p10l {
	padding-left: 10px;
}
.es-p10r {
	padding-right: 10px;
}
.es-p15 {
	padding: 15px;
}
.es-p15t {
	padding-top: 15px;
}
.es-p15b {
	padding-bottom: 15px;
}
.es-p15l {
	padding-left: 15px;
}
.es-p15r {
	padding-right: 15px;
}
.es-p20 {
	padding: 20px;
}
.es-p20t {
	padding-top: 20px;
}
.es-p20b {
	padding-bottom: 20px;
}
.es-p20l {
	padding-left: 20px;
}
.es-p20r {
	padding-right: 20px;
}
.es-p25 {
	padding: 25px;
}
.es-p25t {
	padding-top: 25px;
}
.es-p25b {
	padding-bottom: 25px;
}
.es-p25l {
	padding-left: 25px;
}
.es-p25r {
	padding-right: 25px;
}
.es-p30 {
	padding: 30px;
}
.es-p30t {
	padding-top: 30px;
}
.es-p30b {
	padding-bottom: 30px;
}
.es-p30l {
	padding-left: 30px;
}
.es-p30r {
	padding-right: 30px;
}
.es-p35 {
	padding: 35px;
}
.es-p35t {
	padding-top: 35px;
}
.es-p35b {
	padding-bottom: 35px;
}
.es-p35l {
	padding-left: 35px;
}
.es-p35r {
	padding-right: 35px;
}
.es-p40 {
	padding: 40px;
}
.es-p40t {
	padding-top: 40px;
}
.es-p40b {
	padding-bottom: 40px;
}
.es-p40l {
	padding-left: 40px;
}
.es-p40r {
	padding-right: 40px;
}
.es-menu td {
	border: 0;
}
.es-menu td a img {
	display: inline-block !important;
	vertical-align: middle;
}
/*
END CONFIG STYLES
*/
s {
	text-decoration: line-through;
}
p,
ul li,
ol li {
	font-family: verdana, geneva, sans-serif;
	line-height: 150%;
}
ul li,
ol li {
	margin-bottom: 15px;
	margin-left: 0;
}
a {
	text-decoration: underline;
}
.es-menu td a {
	text-decoration: none;
	display: block;
	font-family: verdana, geneva, sans-serif;
}
.es-wrapper {
	width: 100%;
	height: 100%;
	background-repeat: repeat;
	background-position: center top;
}
.es-wrapper-color,
.es-wrapper {
	background-color: #FBF8F6;
}
.es-header {
	background-color: transparent;
	background-repeat: repeat;
	background-position: center top;
}
.es-header-body {
	background-color: transparent;
}
.es-header-body p,
.es-header-body ul li,
.es-header-body ol li {
	color: #333333;
	font-size: 14px;
}
.es-header-body a {
	color: #333333;
	font-size: 14px;
}
.es-content-body {
	background-color: #ffffff;
}
.es-content-body p,
.es-content-body ul li,
.es-content-body ol li {
	color: #333333;
	font-size: 14px;
}
.es-content-body a {
	color: #333333;
	font-size: 14px;
}
.es-footer {
	background-color: transparent;
	background-repeat: repeat;
	background-position: center top;
}
.es-footer-body {
	background-color: #808080;
}
.es-footer-body p,
.es-footer-body ul li,
.es-footer-body ol li {
	color: #ffffff;
	font-size: 14px;
}
.es-footer-body a {
	color: #ffffff;
	font-size: 14px;
}
.es-infoblock,
.es-infoblock p,
.es-infoblock ul li,
.es-infoblock ol li {
	line-height: 120%;
	font-size: 12px;
	color: #cccccc;
}
.es-infoblock a {
	font-size: 12px;
	color: #cccccc;
}
h1 {
	font-size: 30px;
	font-style: normal;
	font-weight: normal;
	color: #333333;
}
h2 {
	font-size: 24px;
	font-style: normal;
	font-weight: normal;
	color: #333333;
}
h3 {
	font-size: 20px;
	font-style: normal;
	font-weight: normal;
	color: #333333;
}
.es-header-body h1 a,
.es-content-body h1 a,
.es-footer-body h1 a {
	font-size: 30px;
}
.es-header-body h2 a,
.es-content-body h2 a,
.es-footer-body h2 a {
	font-size: 24px;
}
.es-header-body h3 a,
.es-content-body h3 a,
.es-footer-body h3 a {
	font-size: 20px;
}
a.es-button,
button.es-button {
	display: inline-block;
	background: #f0f0f0;
	border-radius: 0px;
	font-size: 16px;
	font-family: arial, 'helvetica neue', helvetica, sans-serif;
	font-weight: normal;
	font-style: normal;
	line-height: 120%;
	color: #333333;
	text-decoration: none !important;
	width: auto;
	text-align: center;
	padding: 5px 30px 5px 30px;
	mso-padding-alt: 0;
	mso-border-alt: 10px solid #f0f0f0;
}
.es-button-border {
	border-style: solid solid solid solid;
	border-color: #808080 #808080 #808080 #808080;
	background: #f0f0f0;
	border-width: 2px 2px 2px 2px;
	display: inline-block;
	border-radius: 0px;
	width: auto;
}
.msohide {
	mso-hide: all;
}
/* RESPONSIVE STYLES Please do not delete and edit CSS styles below. If you don't need responsive layout, please delete this section. */
@media only screen and (max-width: 600px) {
	p,
  ul li,
  ol li,
  a {
		line-height: 150% !important;
	}
	h1,
  h2,
  h3,
  h1 a,
  h2 a,
  h3 a {
		line-height: 120% !important;
	}
	h1 {
		font-size: 30px !important;
		text-align: center;
	}
	h2 {
		font-size: 26px !important;
		text-align: center;
	}
	h3 {
		font-size: 20px !important;
		text-align: center;
	}
	.es-header-body h1 a,
  .es-content-body h1 a,
  .es-footer-body h1 a {
		font-size: 30px !important;
	}
	.es-header-body h2 a,
  .es-content-body h2 a,
  .es-footer-body h2 a {
		font-size: 26px !important;
	}
	.es-header-body h3 a,
  .es-content-body h3 a,
  .es-footer-body h3 a {
		font-size: 20px !important;
	}
	.es-menu td a {
		font-size: 13px !important;
	}
	.es-header-body p,
  .es-header-body ul li,
  .es-header-body ol li,
  .es-header-body a {
		font-size: 16px !important;
	}
	.es-content-body p,
  .es-content-body ul li,
  .es-content-body ol li,
  .es-content-body a {
		font-size: 16px !important;
	}
	.es-footer-body p,
  .es-footer-body ul li,
  .es-footer-body ol li,
  .es-footer-body a {
		font-size: 16px !important;
	}
	.es-infoblock p,
  .es-infoblock ul li,
  .es-infoblock ol li,
  .es-infoblock a {
		font-size: 12px !important;
	}
	*[class='gmail-fix'] {
		display: none !important;
	}
	.es-m-txt-c,
  .es-m-txt-c h1,
  .es-m-txt-c h2,
  .es-m-txt-c h3 {
		text-align: center !important;
	}
	.es-m-txt-r,
  .es-m-txt-r h1,
  .es-m-txt-r h2,
  .es-m-txt-r h3 {
		text-align: right !important;
	}
	.es-m-txt-l,
  .es-m-txt-l h1,
  .es-m-txt-l h2,
  .es-m-txt-l h3 {
		text-align: left !important;
	}
	.es-m-txt-r img,
  .es-m-txt-c img,
  .es-m-txt-l img {
		display: inline !important;
	}
	.es-button-border {
		display: block !important;
	}
	a.es-button,
  button.es-button {
		font-size: 16px !important;
		display: block !important;
		border-left-width: 0px !important;
		border-right-width: 0px !important;
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
	.es-adaptive table,
  .es-left,
  .es-right {
		width: 100% !important;
	}
	.es-content table,
  .es-header table,
  .es-footer table,
  .es-content,
  .es-footer,
  .es-header {
		width: 100% !important;
		max-width: 600px !important;
	}
	.es-adapt-td {
		display: block !important;
		width: 100% !important;
	}
	.adapt-img {
		width: 100% !important;
		height: auto !important;
	}
	.es-m-p0 {
		padding: 0px !important;
	}
	.es-m-p0r {
		padding-right: 0px !important;
	}
	.es-m-p0l {
		padding-left: 0px !important;
	}
	.es-m-p0t {
		padding-top: 0px !important;
	}
	.es-m-p0b {
		padding-bottom: 0 !important;
	}
	.es-m-p20b {
		padding-bottom: 20px !important;
	}
	.es-mobile-hidden,
  .es-hidden {
		display: none !important;
	}
	tr.es-desk-hidden,
  td.es-desk-hidden,
  table.es-desk-hidden {
		width: auto !important;
		overflow: visible !important;
		float: none !important;
		max-height: inherit !important;
		line-height: inherit !important;
	}
	tr.es-desk-hidden {
		display: table-row !important;
	}
	table.es-desk-hidden {
		display: table !important;
	}
	td.es-desk-menu-hidden {
		display: table-cell !important;
	}
	.es-menu td {
		width: 1% !important;
	}
	table.es-table-not-adapt,
  .esd-block-html table {
		width: auto !important;
	}
	table.es-social {
		display: inline-block !important;
	}
	table.es-social td {
		display: inline-block !important;
	}
	.es-desk-hidden {
		display: table-row !important;
		width: auto !important;
		overflow: visible !important;
		max-height: inherit !important;
	}
}
/* END RESPONSIVE STYLES */
.es-p-default {
	padding-top: 20px;
	padding-right: 20px;
	padding-bottom: 20px;
	padding-left: 20px;
}
.es-p-all-default {
	padding: 20px;
}`;
